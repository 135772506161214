// ---- Custom Fonts ----
@import (css) url('https://use.typekit.net/bak6pol.css');

// ---- Root ----
#root {
  height: 100%;
}

// ---- Typography ---
.text-small {
  font-size: 14px;
  line-height: 1.25;
}
// ---- Heading & Typography ----
h1, h2, h3, h4, h5 {
  // add className display to use serif font in the headline
  &.display {
    font-weight: 700 !important;
    font-family: freight-display-pro, serif;
  }
}
.lead {
  font-size: floor(@font-size-base * 1.272)
}

// --- Boxshadow ---
.box-shadow-1 {
  box-shadow: @shadow-1-down;
}

.box-shadow-2 {
  box-shadow: @shadow-2;
}

.box-shadow-3 {
  box-shadow: @shadow-3;
}

// --- Navigation ---
.ant-layout-header, .ant-pro-top-menu {
  height: 72px !important;
  line-height:72px !important;
}
.logo-motto {
  margin-bottom: 5px;
  font-size: @font-size-sm;
  line-height: 2;
  border-left: 2px solid @primary-color-2;
  padding-left: 12px;
  margin-left: 12px;
  vertical-align: middle;
  @media screen and (max-width: @screen-sm) {
    display: none;
  }
}


// --- Container with fixed witdh ---
// .container {
//     width: 100%;
//     display: flex;
//     align-self: center;
//     margin: auto;
// }

// .make-container(@minWidth, @breakpoint) {
//     @media (min-width: @minWidth) {
//         .container {
//             max-width: @breakpoint;
//         }
//     }
// }
//
// .make-container(@screen-xs-min, @screen-xs);
// .make-container(@screen-sm-min, @screen-sm);
// .make-container(@screen-md-min, @screen-md);
// .make-container(@screen-lg-min, @screen-lg);
// .make-container(@screen-xl-min, @screen-xl);
// .make-container(@screen-xxl-min, @screen-xxl);

// --- Card & scenes ---

.scenes .ant-card-body {
  padding: 6px 12px !important;
}

.scenes.ant-card-hoverable:hover {
  border-color:@primary-7 !important;
  .ant-card-body h4 {
      color:@primary-7;
  }
}


// --- Button styles ---
.ant-btn:not(.ant-btn-text):not(.ant-btn-link) {
  &:hover {
    color:@primary-7;
    border-color:@primary-7 !important;
  }
}

.ant-btn-primary {
  &:hover {
    color:@white !important;
    background: @primary-7 !important;
  }
}

/* --- autocomplete-dropdown-container --- */
.autocomplete-dropdown-container {
  box-shadow: @shadow-3;
  z-index: 1;
  position: absolute;
  width: 100%;
}

/* --- extra paddings & margins ---- */
.pb--8 {
  padding-bottom: 8px !important;
}

.pb--16 {
  padding-bottom: 16px !important;
}

.pb--24 {
  padding-bottom: 24px !important;
}

.pb--32 {
  padding-bottom: 32px !important;
}

.pb--64 {
  padding-bottom: 64px !important;
}

.pt--8 {
  padding-top: 8px !important;
}

.pt--16 {
  padding-top: 16px !important;
}

.pt--24 {
  padding-top: 24px !important;
}

.pt--32 {
  padding-top: 32px !important;
}

.pt--64 {
  padding-top: 64px !important;
}

.mb--8 {
  margin-bottom: 8px !important;
}

.mb--16 {
  margin-bottom: 16px !important;
}

.mb--24 {
  margin-bottom: 24px !important;
}

.mb--32 {
  margin-bottom: 32px !important;
}

.mb--64 {
  margin-bottom: 64px !important;
}

.pl--15 {
  padding-left: 15px;
}

.pr--15 {
  padding-right: 15px;
}
